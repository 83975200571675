import "./App.scss";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";

function App() {
  const [answers, setAnswers] = useState({});

  const handleChange = (event) => {
    const id = event.target.name;
    const answer = event.target.value;
    setAnswers((oldAnswers) => {
      return { ...oldAnswers, [id]: answer };
    });
  };

  useEffect(() => {
    console.log(answers);
  }, [answers]);

  const questions = [
    {
      id: "how-active-is-your-child",
      label: "How active is your child?",
      inputLabelId: "how-active-is-your-child",
      onSelect: handleChange,
      value: answers["how-active-is-your-child"],
      options: [
        { label: "Sedentary", value: 1 },
        { label: "Moderately active", value: 2 },
        { label: "Active", value: 3 },
      ],
    },
    {
      id: "is-your-child-gluten-free",
      label: "Is your child gluten free?",
      inputLabelId: "is-your-child-gluten-free",
      onSelect: handleChange,
      value: answers["is-your-child-gluten-free"],
      options: [
        { label: "Yes", value: 1 },
        { label: "No", value: 2 },
      ],
    },
    {
      id: "is-your-child-vegetarian",
      label: "Is your child vegetarian?",
      inputLabelId: "is-your-child-vegetarian",
      onSelect: handleChange,
      value: answers["is-your-child-vegetarian"],
      options: [
        { label: "Yes", value: 1 },
        { label: "No", value: 2 },
      ],
    },
    {
      id: "describe-you-child-attitude-to-health",
      label: "Describe your child's attitude to health",
      inputLabelId: "describe-you-child-attitude-to-health",
      onSelect: handleChange,
      value: answers["describe-you-child-attitude-to-health"],
      options: [
        { label: "Indifferent", value: 1 },
        { label: "Against health", value: 2 },
        { label: "For health", value: 3 },
      ],
    },
    {
      id: "how-old-is-your-child",
      label: "How old is your child?",
      inputLabelId: "how-old-is-your-child",
      onSelect: handleChange,
      value: answers["how-old-is-your-child"],
      options: [
        ...Array.from({ length: 12 }, (_, index) => {
          return { label: index + 2, value: index + 2 };
        }),
      ],
    },
    {
      id: "what-is-your-child-gender",
      label: "What is your child's gender?",
      inputLabelId: "what-is-your-child-gender",
      onSelect: handleChange,
      value: answers["what-is-your-child-gender"],
      options: [
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
      ],
    },
  ];

  return (
    <div className="app">
      <div className="header">Child Health Calculator</div>
      <div className="container">
        <Box sx={{ minWidth: 120 }}>
          {questions.map((question) => (
            <Box key={question.id} marginTop={5}>
              <FormControl fullWidth>
                <InputLabel id={question.inputLabelId}>
                  {question.label}
                </InputLabel>
                <Select
                  name={question.id}
                  labelId={question.inputLabelId}
                  id={question.id}
                  value={question?.value ?? ""}
                  label={question.label}
                  onChange={question.onSelect}
                >
                  {question.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ))}
        </Box>
        <Box marginTop={5} sx={{ "& button": { m: 0 } }}>
          <div>
            <Button variant="contained" size="large" fullWidth>
              SUBMIT
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default App;
